@import 'ngx-toastr/toastr';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}


@font-face {
    font-family: 'graphik';
    src: url('./assets/fonts/Graphik-Regular-Trial.otf');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'graphik';
}

html,
body {
    height: 100%;
    width: 100%;
}

button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background: #EBEBEB;
    color: #A3A3A3;
}

.h1 {
    @apply font-light text-[96px]
}

.h2 {
    @apply font-light text-[60px]
}

.h3 {
    @apply font-normal text-[48px]
}

.h4 {
    @apply font-normal text-[34px]
}

.h5 {
    @apply font-normal text-[24px]
}

.h6 {
    @apply font-medium text-[20px]
}

.circle-progress {
    stroke-dasharray: 100, 100;
}

.subtitle-1 {
    @apply font-normal text-[16px]
}

.subtitle-2 {
    @apply font-medium text-[14px]
}

.button-text {
    @apply font-medium text-[14px]
}

.caption {
    @apply font-normal text-[12px]
}

.overline {
    @apply font-normal text-[10px]
}

/* ::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    border-radius: 20px;
}

::-webkit-scrollbar-track:hover {
    background-color: #EFEEEE;
}

::-webkit-scrollbar-thumb {
    background: #B7B7B7;
} */
 

body input[type="date"]::-webkit-calendar-picker-indicator {
  background: white;
}

button:disabled{
    background: #BDBDBD;
    color: black;
}

.thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
}

#tree,
#service-tree,
#facility-tree,
#resource-audit-tree,
#resource-view-tree,
#resource-view-modal-tree {
    position: relative !important;
    height: 400px !important;
}

#tree svg,
#service-tree svg,
#facility-tree svg,
#resource-audit-tree svg,
#resource-view-tree svg,
#resource-view-modal-tree svg {
    height: 100% !important;
    border: 1px solid #d1d5db !important;
    border-radius: 15px !important;
}

[data-n-id] rect {
    display: none !important;
}

[data-n-id] text {
    align-self: flex-start !important;
    font-size: 30px !important;
    font-weight: bold;
    fill: black !important;
    cursor: pointer !important;
}


text[data-selected="yes"] {
    fill: #2563eb !important;
}

path[data-selected-link="yes"] {
    stroke: #2563eb !important;
}

text[data-selected="no"] {
    fill: black !important;
}

path[data-selected-link="no"] {
    stroke: black !important;
}

[data-n-id] circle {
    display: none !important;
    fill: #2563eb !important;
}

.boc-search {
    display: none !important;
}

.boc-toolbar-layout {
    display: none !important;
}


.boc-toolbar-container {
    position: absolute !important;
    bottom: 20px !important;
    right: 20px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: start !important;
    gap: 2px !important;
}

div[data-tlbr="expand"],
div[data-tlbr="fit"],
div[data-tlbr="plus"],
div[data-tlbr="minus"] {
    padding: 5px !important;
    padding: 2px !important;
}

div[data-tlbr="layout"] {
    display: none !important;
}

.splide__pagination {
    position: static !important;
}

.splide__pagination__page.is-active {
    background-color: #2563eb !important;
}

.splide__pagination__page {
    background-color: #EBEBEB !important;
}

.splide__arrows button {
    background-color: #EBEBEB !important;
}

.opaque {
    opacity: 0;
}

.map-container {
    width: 100% !important;
}


/* styles.css or styles.scss */
.toast-success {
    background-color: #53cc16 !important;
    color: #fff !important;
}

.toast-error {
    background-color: #dc3545 !important;
    color: #fff !important;
}

.toast-warning {
    background-color: #ffc107 !important;
    color: #fff !important;
}

.toast-info {
    background-color: #17a2b8 !important;
    color: #fff !important;
}

.toast-progress {
    background-color: #fff !important;
}

#toast-container>div {
    border-radius: 8px !important;
}